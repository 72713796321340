/* eslint-disable no-unused-vars */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css"; // Importa los estilos CSS de AOS
import NavBar from "../Website/components/01-NavBar";
import BannerSection from "./02-BannerSection";

import "../Website/css/owl.carousel.css";
import "../Website/css/style.css";
import Footer from "../Website/components/09-Footer";
import EmailForm from "../Website/components/email/IndexModal";




const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const EmailIndex = (props) => {

  useEffect(() => {
    AOS.init(); // Inicializa AOS en el componente
  }, [])
  const [show, setShow] = useState(false)


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = props;

  return (
    <>
      <NavBar handleShow={handleShow} {...props} />
      <BannerSection handleShow={handleShow} t={t} />
      <EmailForm  />
      {/* <Pricing t={t} /> */}
      <Footer t={t} />


    </>
  );
}



EmailIndex.propTypes = propTypes;
EmailIndex.defaultProps = defaultProps;

export default withTranslation()(EmailIndex);