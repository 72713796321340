import React, { useState, useEffect } from "react";
import "../Components2/Language";
import Logo from '../images/logo_test.jpg';
import './css/style.css';
import { Button, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";



function NavBar({ handleShow }) {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };


    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let navbarClasses = ['navbar', 'navbar-expand-lg', 'sticky-header2', 'custom-nav', 'sticky-header'];
  if (scrolled) {

    navbarClasses.push('sticky-header');
  }


  const [show, setShow] = useState(false);


  return (
    <nav className={navbarClasses.join(' ')} style={{
      top: 0,
      width: '100ww',
      zIndex: 1000 /* Asegúrate de que la barra de navegación esté por encima de otros elementos */

    }} >
      <div className="container" >

        {/* <img
            src={Logo}
            className="logo"
            alt="hola"
            style={{ filter: 'drop-shadow(-2px 2px 1px rgba(0, 0, 0, 0.7))' }}
          /> */}

        <div className="col-lg-12" style={{
          display: 'flex',
          gap: 20,
          padding: 0,
          justifyContent: 'space-between',
          alignItems: 'space-between',

        }} >
          <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            fontWeight: 'bold',
            color: '#3773FE',
            flex: 4,
            fontSize: 1.3 + 'em',
          }}
          >
            <Link to="/" style={{ color: '#3773FE', textDecoration: 'none' }}><img
              src={Logo}
              alt=""
              className="img-fluid"
              style={{
                width: 150,
                height: 50 + 'px'
              }}
            />
            </Link>
          </div>
          <div className="col-lg-2.5" style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 20,
            flex: 1,
          }} >



          </div>




        </div>


      </div >

    </nav >

  );
}


export default NavBar;

