import React, { Component } from "react";
import About02 from "../images/nosotros01.png";
import About01 from "../images/nosotros02.png";
import { BiCheck } from "react-icons/bi";

class Nosotros extends Component {

  render() {

    return (
      <section id="aboutus" className="section" style={{ background: '#f8f9fa' }}>
        <div className="container">
          <div className="title-block mx-auto">
            <div className="section_title mb-4 text-center">
              <h2><span>{this.props.t("Website.contact.title")}</span></h2>

              <p className="section_subtitle mx-auto">
              Firma con confianza Con nuestra tecnología avanzada, tus documentos estarán protegidos y firmados con la máxima seguridad.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12">
              <div
                data-wow-delay="0.2s"
                data-wow-duration="1.5s"
                className="about-image-block wow fadeInUp"

              >
                <img data-aos="fade-down-right"
                  src={About02}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div
                data-wow-delay="0.4s"
                data-wow-duration="1.5s"
                className="about-text-block wow fadeInUp"

              >
                <div className="about-content" data-aos="fade-down-left">
                  <h4 style={{ whiteSpace: 'pre-wrap' }}>Seguridad De La Firma Digital</h4>
                  <div className="text">
                    Protección de nivel empresarial Nos tomamos la seguridad en serio. Nuestra plataforma utiliza cifrado de última generación para proteger tus datos y garantizar la autenticidad de cada firma. Cumplimos con las normativas legales internacionales y brindamos tranquilidad en cada transacción.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12 order-md-2">
              <div
                data-wow-delay="0.4s"
                data-wow-duration="1.5s"
                className="about-image-block wow fadeInUp"

              >
                <img
                  data-aos="fade-up-left"
                  src={About01}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 order-md-1" data-aos="fade-up-right">
              <div
                className="about-text-block wow "
              >
                <div className="about-content" >
                  <h4 style={{ whiteSpace: 'pre-wrap' }}> Fácil y rápido en 3 sencillos pasos</h4>
                  <div className="text" style={{
                    padding: '0 20px' ,
                  }}>
                    <p><BiCheck color="green" /> Carga tu documento: Sube tu archivo a nuestra plataforma.</p>
                    <p><BiCheck color="green" /> Invita a los firmantes: Agrega a las personas que deben firmar el documento.</p>
                    <p><BiCheck color="green" /> Firma digitalmente: Cada firmante firma desde cualquier lugar y en cualquier dispositivo.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>
    );
  }
}

export default Nosotros;