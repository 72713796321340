import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import SLIDER1 from '../images/1.png';
import SLIDER2 from '../images/2.png';
import SLIDER3 from '../images/2.1.png';
import SLIDER4 from '../images/3.0.png';
import SLIDER5 from '../images/3.1.png';
import { Button } from 'react-bootstrap';
import { IoIosArrowDroprightCircle } from "react-icons/io";

export default function BannerSection() {

    return (
 <Carousel

            className="home-section"
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={false}
            showIndicators={false}
            emulateTouch={false}
            labels={false}
            stopOnHover={false}
            touchScrollEnabled={false}
            mouseDragEnabled={false}
            
            
            swipeable={false}
            renderArrowPrev={(onClickHandler, _hasPrev, label) => {
                return (
                    <button type="button" onClick={onClickHandler} title={label} style={{
                        position: 'absolute',
                        bottom: 10,
                        zIndex: 100,
                        left: 0,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                    }}>
                        <img src="https://www.bccr.fi.cr/SiteAssets/Flecha-izquierda.png" />
                    </button>
                );
            }}
            renderArrowNext={(onClickHandler, hasPrev, label) => {
                return (
                    <><button type="button" onClick={onClickHandler} title={label} style={{
                        position: 'absolute',
                        bottom: 10,
                        zIndex: 100,
                        left: 80,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                    }}>
                        <img src="https://www.bccr.fi.cr/SiteAssets/Flecha-derecha.png" />
                    </button></>
                );
            }}

        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundPosition: '0 10',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '100%',
             WebkitBackdropFilter: 'blur(10px)',
                padding: '50px 0',
            }}>
                
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-4">
                            <div style={{
                                color: '#FFF',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}>
                                <div style={{
                                    fontWeight: 'bold',
                                    fontSize: 28,
                                    color: '#FFF',
                                    width: '100%',
                                    padding: 0,
                                    margin: 0,
                                }}>MONEDA CONMEMORATIVA</div>
                                <div style={{
                                    fontSize: 23,
                                    color: '#FFF',
                                    width: '100%',
                                    padding: 0,
                                    margin: 0,
                                    textAlign: 'center',
                                }}>
                                    200 años de la Anexión del Partido de Nicoya a Costa Rica . 1824-2024
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: 20,
                        }}>
                            <img src={SLIDER1} style={{
                                borderRadius: 10,
                            }} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundPosition: '0 10',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                padding: '50px 0',
            }}>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-4">
                            <div style={{
                                color: '#FFF',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}>
                                <div style={{
                                    fontWeight: 'bold',
                                    fontSize: 24,
                                    color: '#FFF',
                                    width: '100%',
                                    padding: 0,
                                    margin: 0,
                                }}>PROGRAMA DE COLECCOPMES</div>
                                <div style={{
                                    fontSize: 19,
                                    color: '#FFF',
                                    width: '100%',
                                    padding: 0,
                                    margin: 0,
                                    textAlign: 'center',
                                    letterSpacing: 4,
                                }}>
                                    HITOS DE NUESTRA HISTORIA
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: 20,
                        }}>
                            <div className='row'>
                                <img className='col-lg-6 col-md-12' src={SLIDER2} style={{
                                    borderRadius: 10,
                                }} />
                                <img className='col-lg-6 col-md-12' src={SLIDER3} style={{
                                    borderRadius: 10,
                                }} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundPosition: '0 10',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                padding: '50px 0',
            }}>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div style={{
                                color: '#FFF',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}>
                                <div style={{
                                    fontWeight: '500',
                                    fontSize: 24,
                                    color: '#FFF',
                                    width: '100%',
                                    padding: 0,
                                    margin: 0,
                                    letterSpacing: 2,
                                    marginBottom: 20,
                                }}>CALENDARIO DE REUNIONES DE POLITICA MONETARIA PARA 2024</div>
                       
                            </div>
                        </div>
                        <div className="col-lg-12" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: 20,
                        }}>
                            <div className='row' style={{
                            }}>
                                <img className='col-lg-6 col-md-12' src={SLIDER5} style={{
                                    borderRadius: 10,
                                    marginBottom: 20,
                                }} />
                                <img className='col-lg-6 col-md-12' src={SLIDER4} style={{
                                    borderRadius: 10,
                                    marginBottom: 20,
                                }} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
                <img src="https://www.bccr.fi.cr/Banner/2.png" height={500} />
            </div>
            <div>
                <img src="https://www.bccr.fi.cr/Banner/3.png" height={500} />
            </div>
 */}
        </Carousel>
    );
}


