import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { FaFacebookSquare } from 'react-icons/fa';
import Logo from '../images/logo_test.jpg';
import { Link } from 'react-scroll';

class Footer extends Component {
  render() {
    return (
      <footer className="container-fluid" style={{backgroundColor:'#FFF !important'}}>
        <div className="container">
          <div className="row">
            
                <div  style={{ 
                    display:'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: '40px 0px',
                    gap: 20,
                    flexWrap: 'wrap',
                  }}>
                    <div style={{
                      display: 'flex',
                      gap: 20,
                      flex: 1,
                      flexDirection: 'column',
                    }}>
                      <img src={Logo} alt="ProtecSoft" style={{width: '200px'}}/>
                       </div>
                    
                  <div className="wp-block-column is-layout-flow wp-container-core-column-is-layout-12 wp-block-column-is-layout-flow" style={{
                      padding: '0px 20px',
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      gap: 15,
                    }}>
                    <h3 className="wp-block-heading has-medium-large-font-size" style={{ fontStyle: 'normal', fontWeight: 600 }}>Empresa</h3>
                    <Link to="home" smooth={true} style={{cursor: 'pointer'}} duration={500}>
                      <p style={{
                        width: '90%',
                        
                        color: 'rgba(13,23,59,0.8)'
                      }}> Inicio </p>
                      </Link>
                      <Link to="aboutus" smooth={true} style={{cursor: 'pointer'}} duration={500}>
                      <p style={{
                        width: '90%',
                        
                        color: 'rgba(13,23,59,0.8)'
                      }}>Nosotros</p>
                      </Link>
                      <Link to="features" smooth={true} style={{cursor: 'pointer'}} duration={500}>
                      <p style={{
                        width: '90%',
                        
                        color: 'rgba(13,23,59,0.8)'
                      }}> Servicios</p>
                      </Link>
                      <Link to="elegirnos" smooth={true} style={{cursor: 'pointer'}} duration={500}>
                      <p style={{
                        width: '90%',
                        
                        color: 'rgba(13,23,59,0.8)'
                      }}> ¿Por qué elegirnos?</p>
                      </Link>


                  </div>
                  {/*<div className="wp-block-column is-layout-flow wp-container-core-column-is-layout-12 wp-block-column-is-layout-flow" style={{
                      padding: '0px 20px',
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      gap: 15,
                    }}>
                     <h3 className="wp-block-heading has-medium-large-font-size" style={{ fontStyle: 'normal', fontWeight: 600 }}>Ponte en contacto</h3>
                    
                    <div style={{
                      display: 'flex',
                      gap: 10,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <img src='https://www.protecsoft.cl/wp-content/themes/blockscape/assets/images/map.svg' alt='' height={20} width={20} />
                    <p style={{
                        width: '100%',
                        margin: 0,
                        color: 'rgba(13,23,59,0.8)'
                      }}> alguna direccion </p>
                    </div>
                    <div style={{
                      display: 'flex',
                      gap: 10,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <img src='https://www.protecsoft.cl/wp-content/themes/blockscape/assets/images/contact-info-email.svg' alt='' height={20} width={20} />
                    <p style={{
                        width: '100%',
                        margin: 0,
                        color: 'rgba(13,23,59,0.8)'
                      }}> contacto@contacto.com </p>
                    </div>
                    <div style={{
                      display: 'flex',
                      gap: 10,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <img src='https://www.protecsoft.cl/wp-content/themes/blockscape/assets/images/contact-info-phone.svg' alt='' height={20} width={20} />
                    <p style={{
                        width: '100%',
                        margin: 0,
                        color: 'rgba(13,23,59,0.8)'
                      }}> +56 9 7184 0546 </p>
                    </div>
                       
                      


                
                  </div>*/}
                  
                </div>
                <div style={{
                  display:'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  width: '100%',
                }}> 
                    <div style={{
                      fontSize: '1rem',
                    }}>
                     © 2024 Plataforma BCCR en línea. Todos los derechos reservados.
                    </div>
                  </div>
          </div>
        </div>  
      </footer>
    );
  }
}

export default Footer;



// import React, { Component } from 'react';




// class Footer extends Component {

//   render() {
    // return (
    //     <footer className="container-fluid" >
    //       <div className="container">
    //       <div className="col-lg-6">
    //         <div className="row">
    //           <div className="col-lg-12" id="gtco-footer">
    //             <h4> {this.props.t("Website.contact.contact_us")} </h4>
    //             <input
    //               type="text"
    //               className="form-control"
    //               placeholder={this.props.t("Summary.labels.fullName")}
    //             />
    //             <input
    //               type="email"
    //               className="form-control"
    //               placeholder={this.props.t("Summary.labels.email")}
    //             />
    //             <textarea
    //               className="form-control"
    //               placeholder={this.props.t("Website.contact.message")}
    //             ></textarea>
    //             <a href="#asd" className="submit-button">
    //             {this.props.t("Website.contact.send_message")} <i className="fa fa-angle-right" aria-hidden="true"></i>
    //             </a>
    //             <div className="col-12" style={{textAlign:'center'}}> 
    //                 <p>
    //                   &copy; 2023. All Rights Reserved. Design by{" "}
    //                   <a href="https://modevit.com/">MODEVIT.COM</a>.
    //                 </p>
    //               </div>
    //           </div>
    //            </div>



    //           </div>
    //           <div className="col-lg-6">
    //             <div className="row">
    //               <div className="col-6">
    //                 <h4>Company</h4>
    //                 <ul className="nav flex-column company-nav">
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#/">
    //                       Home
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       Services
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       About
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       News
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       FAQ's
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       Contact
    //                     </a>
    //                   </li>
    //                 </ul>
    //                 <h4 className="mt-5">Follow Us</h4>
    //                 <ul className="nav follow-us-nav">
    //                   <li className="nav-item">
    //                     <a className="nav-link pl-0" href="#2">
    //                       <i className="fa fa-facebook" aria-hidden="true"></i>
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#3">
    //                       <i className="fa fa-twitter" aria-hidden="true"></i>
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#4">
    //                       <i className="fa fa-google" aria-hidden="true"></i>
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       <i className="fa fa-linkedin" aria-hidden="true"></i>
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //               <div className="col-6">
    //                 <h4>Services</h4>
    //                 <ul className="nav flex-column services-nav">
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       Web Design
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       Graphics Design
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       App Design
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       SEO
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       Marketing
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a className="nav-link" href="#asd">
    //                       Analytic
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
                 
    //             </div>
    //           </div>
            
    //       </div>
    //     </footer>
   // );
//   }
// }

// export default Footer;