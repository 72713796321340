import React from 'react';
import Img01 from '../images/ser01.png';
import Img03 from '../images/ser02.png';
import Img02 from '../images/ser03.webp';

function Services(props) {


  return (
    <section id="features" className="section" style={{ background: '#FFF !important' }}>
      <ul className="circles fetures-circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="container">

        <div className="section_title mb-4 text-center">

          <h2><span>Servicios y soluciones.</span></h2>

          <p className="section_subtitle mx-auto">
            {props.t("Website.services.tittle")}
            Soluciones de firma digital adaptadas a ti
          </p>
        </div>
        <div className="container gtco-featured">
          <div className="row">

            <div className="col-lg-12 our-services">
              <svg id="bg-services" width="100%" height={"145%"} viewBox="0 0 1200 1000">
                <defs>
                  <linearGradient
                    id="PSgrad_02"
                    x1="64.279%"
                    x2="0%"
                    y1="76.604%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      stopColor="rgb(1,230,248)"
                      stopOpacity="1"
                    />
                    <stop
                      offset="100%"
                      stopColor="rgb(29,62,222)"
                      stopOpacity="1"
                    />
                  </linearGradient>
                </defs>
                <path
                  fill-rule="evenodd"
                  opacity="0.102"
                  fill="url(#PSgrad_02)"
                  d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z"
                />
              </svg>
              <div className="row">
                <div className="col" style={{ marginTop: 0 }}>
                  <div data-aos-delay="0" className="card text-center">
                    <div className="oval" style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={Img01} height={100} width={150} style={{
                        rotate: '-20deg',
                      }} alt='' />
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">
                        Firma electrónica avanzada
                      </h3>
                      <p className="card-text">
                        La Firma Electrónica Avanzada es una solución robusta y segura diseñada para proteger la integridad de tus documentos y garantizar que cada firma sea autentificada de manera precisa y confiable.
                      </p>
                    </div>
                  </div>



                </div>
                <div className="col">
                  <div data-aos-delay="0" className="card text-center">
                    <div className="oval" style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={Img03} height={100} width={180} style={{
                        rotate: '-20deg',
                      }} alt='' />
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">
                        Firma cualificada
                      </h3>
                      <p className="card-text">
                        La Firma Electrónica Cualificada es el único tipo de firma electrónica que tiene el mismo estatus jurídico que una firma manuscrita, siempre que se realice bajo las condiciones establecidas por la ley.
                      </p>
                    </div>
                  </div>






                </div>
                <div className="col" style={{ marginTop: 0 }}>


                  <div data-aos-delay="0" className="card text-center">
                    <div className="oval" style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={Img02} height={100} width={180} style={{
                        rotate: '-20deg',
                      }} alt='' />
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">
                        Automatización de flujos de trabajo
                      </h3>
                      <p className="card-text">
                        En ocasiones, una solución de software no basta; necesitas una versión que te acompañe en terreno. Las aplicaciones móviles permiten llevar todo el potencial de tu software en tu smartphone o tablet.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}


export default Services;