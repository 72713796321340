import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import SLIDER1 from './images/slider1.jpg';
import SLIDER2 from './images/slider2.jpg';
import { Button } from 'react-bootstrap';
import { IoIosArrowDroprightCircle } from "react-icons/io";

export default function BannerSection() {

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });


    const useWindowSize = () => {
        const [windowSize, setWindowSize] = useState({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            };

            window.addEventListener('resize', handleResize);

            // Cleanup event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        return windowSize;
    };
    const { width, height } = useWindowSize();
    console.log(width)
    return (
        
        <Carousel

            className="home-section"
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={false}
            showIndicators={false}
            emulateTouch={false}
            labels={false}
            stopOnHover={false}
            touchScrollEnabled={false}
            mouseDragEnabled={false}


            swipeable={false}
            renderArrowPrev={(onClickHandler, _hasPrev, label) => {
                return (
                    <button type="button" onClick={onClickHandler} title={label} style={{
                        position: 'absolute',
                        bottom: 10,
                        zIndex: 100,
                        left: 0,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                    }}>
                        <img src="https://www.bccr.fi.cr/SiteAssets/Flecha-izquierda.png" />
                    </button>
                );
            }}
            renderArrowNext={(onClickHandler, hasPrev, label) => {
                return (
                    <><button type="button" onClick={onClickHandler} title={label} style={{
                        position: 'absolute',
                        bottom: 10,
                        zIndex: 100,
                        left: 80,
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                    }}>
                        <img src="https://www.bccr.fi.cr/SiteAssets/Flecha-derecha.png" />
                    </button></>
                );
            }}

        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundPosition: '0 0',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                WebkitBackdropFilter: 'blur(10px)',
                padding: '50px 0',
                backgroundImage: `url(${SLIDER1})`,
                backgroundSize: '100% 100%',
                minHeight: width > 650 ? 600 : 300,
            }}>

                <div className='container'>
                    <div className="row">

                        <div className="col-lg-12 col-md-12" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: 20,
                        }}>
                            {/*  <img src={SLIDER1} style={{
                                borderRadius: 10,
                            }} /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundPosition: '0 0',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                WebkitBackdropFilter: 'blur(10px)',
                padding: '50px 0',
                backgroundImage: `url(${SLIDER2})`,
                backgroundSize: '100% 100%',
            }}>

                <div className='container'>
                    <div className="row">

                        <div className="col-lg-12 col-md-12" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: 20,
                        }}>
                            {/*  <img src={SLIDER1} style={{
                                borderRadius: 10,
                            }} /> */}
                        </div>
                    </div>
                </div>
            </div>


            {/* <div>
                <img src="https://www.bccr.fi.cr/Banner/2.png" height={500} />
            </div>
            <div>
                <img src="https://www.bccr.fi.cr/Banner/3.png" height={500} />
            </div>
 */}
        </Carousel>
    );
}


