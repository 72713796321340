import React from 'react';
import Img01 from '../images/pasos.png';
import Img03 from '../images/3.jpg';
import Img02 from '../images/firma.png';
import '../../assets/fonts/icomoon/icomoon/style.css'
import { Button } from 'react-bootstrap';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { Link } from 'react-router-dom';

function Citas({handleShow}) {


  return (
    <section id="features" className="section" style={{ background: '#FFF !important' }}>
      <ul className="circles fetures-circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="container">

        <div className="section_title mb-4 text-center">

          <h2><span>Solicitud de cita.</span></h2>

          <p className="section_subtitle mx-auto">
            
            Soluciones de firma digital adaptadas a ti
          </p>
        </div>
        <div className="container gtco-featured">
          <div className="row">

            <div className="col-lg-12 our-services">
              <svg id="bg-services" width="100%" height={"145%"} viewBox="0 0 1200 1000">
                <defs>
                  <linearGradient
                    id="PSgrad_02"
                    x1="64.279%"
                    x2="0%"
                    y1="76.604%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      stopColor="rgb(1,230,248)"
                      stopOpacity="1"
                    />
                    <stop
                      offset="100%"
                      stopColor="rgb(29,62,222)"
                      stopOpacity="1"
                    />
                  </linearGradient>
                </defs>
                <path
                  fill-rule="evenodd"
                  opacity="0.102"
                  fill="url(#PSgrad_02)"
                  d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z"
                />
              </svg>
              <div className="row">
                <div className="col-lg-4 col-md-12" style={{ marginTop: 0 }}>
                  <div data-aos-delay="0" className="card text-center">
                    <div className="oval" style={{ height: 120, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={Img01} height={140} width={150} style={{
                        rotate: '-20deg',
                      }} alt='' />
                    </div>
                    <div className="card-body" >
                      <h3 className="card-title">
                        Trámites En Línea
                      </h3>
                      <div className="card-text" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 5,
                      }}>
                        <div style={{
                          textAlign: 'left',
                          widows: 'fit-content',
                        }}>
                          <p><span class="icon-check-circle-o"></span> Central Directo</p>
                          <p><span class="icon-check-circle-o"></span> Firma Digital</p>
                          <p><span class="icon-check-circle-o"></span>Citas En Línea</p>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-lg-4 col-md-12" style={{ marginTop: 0 }}>
                  <div data-aos-delay="0" className="card text-center">
                  <div className="oval" style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={Img02} height={90} width={180} style={{
                        rotate: '-20deg',
                      }} alt='' />
                    </div>
                    <div className="card-body" >
                    
                      
                      
                      <div className="" style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Link style={{
                          border: '1px solid #3773FE !important',
                          color: '#FFF !important',
                          display: 'flex',
                          zIndex: 100,
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '50%',
                          background: '#0657a3',
                          fontWeight: 'bold',
                          flex: 3,
                          gap: 5,
                          padding: 10,
                          borderRadius: 10,
                        }}
                        to="/contact"
                        
                          
                        >Obtener cita {/* <IoIosArrowDroprightCircle size={22} style={{}} /> */}</Link>
                      </div>
                      <div className="card-text" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 5,
                      }}>
                         <p className="card-text">
                         Firma con confianza Con nuestra tecnología avanzada, tus documentos estarán protegidos y firmados con la máxima seguridad.
                        </p>
                      

                      </div>
                    </div>
                  </div>




                </div>






                <div className="col-lg-4 col-md-12" style={{ marginTop: 0 }}>


                  <div data-aos-delay="0" className="card text-center">
                    <div className="oval" style={{ height: 110, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={Img03} height={120} width={120} style={{
                        rotate: '-20deg',
                        borderRadius: '50% / 70% 20% 70% 20%',
                      }} alt='' />
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">
                        Solicité Su Firma Digital
                      </h3>
                      <p className="card-text">
                        Con nuestra tecnología avanzada, tus documentos estarán protegidos y firmados con la máxima seguridad. Olvídate del papel, firma de manera digital y lleva tu negocio al siguiente nivel.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}


export default Citas;