import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EmailForm from './IndexModal';

function Email({ show, handleClose}) {


  return (
    <>

      <Modal show={show} onHide={handleClose} 
      style={{
        background: 'transparent !important',
        
      }}
      sx={{
        background: 'transparent !important',
      }}
        backdrop="static"
        keyboard={false}
        size="lg"
        >

        <EmailForm handleClose={handleClose} />

      </Modal>
    </>
  );
}

export default Email;