import React from 'react';
import ImgWork from '../images/elegirnos01.png';

function Numbers(props) {

  return (
    <section id="elegirnos" className="section" style={{ background: '#f8f9fa' }}>
      <div className="container">
        <div className="title-block mx-auto">
          <div className="section_title mb-4 text-center">
            <h2><span>{props.t("Website.tags.tittle")}</span></h2>

            <p className="section_subtitle mx-auto">
            Nuestra plataforma utiliza cifrado de última generación para proteger tus datos y garantizar la autenticidad de cada firma.
            </p>
          </div>
        </div>
        <div className="work-steps-process">
          <div className="work-steps-box">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <div
                  data-wow-delay="0.3s"
                  data-wow-duration="1000ms"
                  className="work-steps-detail d-flex align-items-start wow fadeInLeft"

                >
                  <div className="steps-icon">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                        <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                      </svg>
                    </i>
                  </div>
                  <div className="steps-text">
                    <h4>Legalmente vinculante</h4>
                    <p className="step-desc">
                      Todas nuestras firmas digitales cumplen con la normativa vigente.
                    </p>
                  </div>
                </div>
                <div
                  data-wow-delay="0.6s"
                  data-wow-duration="1000ms"
                  className="work-steps-detail d-flex align-items-start wow fadeInLeft"

                >
                  <div className="steps-icon">
                    <i >
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-shield-fill-check" viewBox="0 0 16 16">
                        <path d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.8 11.8 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7 7 0 0 0 1.048-.625 11.8 11.8 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.54 1.54 0 0 0-1.044-1.263 63 63 0 0 0-2.887-.87C9.843.266 8.69 0 8 0m2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793z" />
                      </svg>
                    </i>
                  </div>
                  <div className="steps-text">
                    <h4>Ahorro de tiempo</h4>
                    <p className="step-desc">
                      Firma documentos en segundos, sin importar la distancia.
                    </p>
                  </div>
                </div>
                <div
                  data-wow-delay="0.9s"
                  data-wow-duration="1000ms"
                  className="work-steps-detail d-flex align-items-start wow fadeInLeft"

                >
                  <div className="steps-icon">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-tags-fill" viewBox="0 0 16 16">
                        <path d="M2.5 7.775V2.5a.5.5 0 0 1 .5-.5h5.275a.5.5 0 0 1 .354.146l6.25 6.25a.5.5 0 0 1 0 .708l-5.275 5.275a.5.5 0 0 1-.708 0l-6.25-6.25a.5.5 0 0 1-.146-.354zm-1 0V2.5A1.5 1.5 0 0 1 3 1h5.275a1.5 1.5 0 0 1 1.06.44l6.25 6.25a1.5 1.5 0 0 1 0 2.12l-5.275 5.275a1.5 1.5 0 0 1-2.12 0l-6.25-6.25A1.5 1.5 0 0 1 1.5 7.775zM6 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                    </i>
                  </div>
                  <div className="steps-text">
                    <h4>Accesible desde cualquier lugar</h4>
                    <p className="step-desc">
                    Usa nuestra plataforma desde cualquier dispositivo con acceso a internet.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div

                  className="work-steps-images wow fadeInRight"

                >
                  <img
                    src={ImgWork}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


export default Numbers;