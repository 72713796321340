import React, { useState } from 'react';
import IMG1 from '../images/img1.png';
import IMG2 from '../images/img2.png';
import IMG3 from '../images/img3.png';
import IMG4 from '../images/img8.png';
import IMG5 from '../images/img7.png';
import IMG6 from '../images/img6.png';
import IMG7 from '../images/img9.png';
import IMG8 from '../images/img10.png';
import IMG9 from '../images/img11.png';
import Marquee from 'react-fast-marquee';

const Testimonials = () => {
  const [count] = useState(0);
  const [count2] = useState(0);
  const [count3] = useState(0);
  const [count4] = useState(0);

  return (
    <section className="testimonials-section" style={{ backgroundColor: 'rgb(215, 215, 215)' }}>
      <Marquee style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      direction='left'
      pauseOnHover={true}
      gradient={false}
      speed={40}

      >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img src={IMG2} alt='' />
                <img src={IMG3} alt='' />
                <img src={IMG4} alt='' />
                <img src={IMG5} alt='' />
                <img src={IMG6} alt='' />
                <img src={IMG1} alt='' />
                <img src={IMG7} alt='' />
                <img src={IMG8} alt='' />
                <img src={IMG9} alt='' />
        </div>
        </Marquee>
    </section>
  );
};

export default Testimonials;