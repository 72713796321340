import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'react-bootstrap';

import i18n from '../services/i18n';

function Language() {

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }
  const toKebabCase = (str) => {
    return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
  }
  const Lang = {
    title: i18n.language === "es" ? 'Español' : i18n.language === "fr" ? 'Français' : 'English',
    icono: i18n.language === "es" ? 'cifEs' : i18n.language === "fr" ? 'cifFr' : 'cifGb',
  }

  return (

    <Dropdown>
      <Dropdown.Toggle className="btn btn-sm">
      {/* <CIcon style={{height: '1.5rem'}} name={toKebabCase(Lang.icono)} size="sm" /> */} {Lang.title}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {i18n.language !== "es" &&
          <Dropdown.Item onClick={() => { changeLanguage("es") }}>
            {/* <CIcon style={{height: '1.5rem'}} name={toKebabCase("cifEs")} size="sm" /> */} Español
          </Dropdown.Item>
        }
        {i18n.language !== "fr" &&
          <Dropdown.Item onClick={() => { changeLanguage("fr") }}>
            {/* <CIcon style={{height: '1.5rem'}} name={toKebabCase("cifFr")} size="sm" /> */} Français
          </Dropdown.Item>
        }
        {i18n.language !== "en" &&
          <Dropdown.Item onClick={() => { changeLanguage("en") }}>
            {/* <CIcon style={{height: '1.5rem'}} name={toKebabCase("cifGb")} size="sm" /> */} English
          </Dropdown.Item>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}


Language.propTypes = {
  children: PropTypes.node,
};

Language.defaultProps = {};


export default Language;
