/* eslint-disable no-unused-vars */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import AOS from "aos";
import "aos/dist/aos.css"; // Importa los estilos CSS de AOS
import NavBar from "./components/01-NavBar";
import BannerSection from "./components/02-BannerSection";
import Nosotros from "./components/03-Nosotros";
import Services from "./components/04-Services";
import Numbers from "./components/05-Numbers";
import Testimonials from "./components/06-Testimonials";

import "./css/owl.carousel.css";
import "./css/style.css";
import Email from "./components/email/Email";
import Footer from "./components/09-Footer";
import Citas from "./components/Cita";




const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const Website = (props) => {

  useEffect(() => {
    AOS.init(); // Inicializa AOS en el componente
  }, [])
  const [show, setShow] = useState(false)


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);




  const { t } = props;

  return (
    <>
      <NavBar handleShow={handleShow} {...props} />
      <BannerSection handleShow={handleShow} t={t} />
      <Citas t={t}  handleShow={handleShow}   />
      <Nosotros t={t} />
      <Services t={t} />


      <Numbers t={t} />
      <Testimonials t={t} />
      <Email handleClose={handleClose} show={show} />
      {/* <Pricing t={t} /> */}
      <Footer t={t} />


    </>
  );
}



Website.propTypes = propTypes;
Website.defaultProps = defaultProps;

export default withTranslation()(Website);