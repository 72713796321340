import Website from "./Website/Website";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


function App() {
  return (
    <Website />
  );
}

export default App;
