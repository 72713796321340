import { Select, InputLabel, MenuItem } from '@mui/material';
import { min } from 'moment';
import React, { useState } from 'react';

const styles = {
  formContainer: {
    maxWidth: '100%',
    margin: '40px auto',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
  },
  title: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '30px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  inputGroup: {
    marginBottom: '20px',
    display: 'flex',
    gap: 10
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    color: '#555',
    fontSize: '14px',
    fontWeight: '500',
  },
  input: {
    width: '100%',
    padding: '10px 12px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '16px',
    transition: 'border-color 0.3s ease',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#0056b3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
};

const EmailForm = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    idNumber: '',
    email: '',
    retirementDate: '',
    businessManagementNumber: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    // Aquí iría la lógica para enviar los datos del formulario
  };

  return (
    <div style={{
      backgroundColor: '#f5f5f5',
      width: '100%',
    }}>
      <div className="container" >
        <div className="row">
          <div className='col-lg-12 col-md-12' style={{
            padding: '50px 0',
            paddingBottom: 10
          }}>
            <div className="title-block mx-auto">
              <div className="section_title mb-4 text-center">
                <h2><span>Obtener cita</span></h2>

                <p className="section_subtitle mx-auto">
                Por favor, introduzca los datos necesarios para validar su cita previa.
                </p>
              </div>
            </div>
          </div>
          <div style={styles.formContainer} className='col-lg-6 col-md-12'>

            <h2 style={styles.title}>Solicitud de cita</h2>
            <form onSubmit={handleSubmit}>
              <div style={styles.inputGroup}>
                <div style={{
                  width: '100%',

                }}>
                  <label htmlFor="fullName" style={styles.label}>Nombre Completo</label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>

              </div>

              <div style={styles.inputGroup}>
                <div style={{
                  width: '100%',

                }}>
                  <label htmlFor="email" style={styles.label}>Correo Electrónico</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>
              </div>
              <div style={styles.inputGroup}>
                <div style={{
                  width: '50%',

                }}><label htmlFor="idNumber" style={styles.label}>Cédula</label>
                  <input
                    type="text"
                    id="idNumber"
                    name="idNumber"
                    value={formData.idNumber}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>
                <div style={{
                  width: '50%',

                }}>
                  <label htmlFor="retirementDate" style={styles.label}>Fecha de Retiro</label>
                  <input
                    type="date"
                    id="retirementDate"
                    name="retirementDate"
                    value={formData.retirementDate}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>
              </div>
              <div style={styles.inputGroup}>

                <div style={{
                  width: '100%',

                }}>
                  <label htmlFor="businessManagementNumber" style={styles.label}>Número de Gestión Empresarial</label>
                  <input
                    type="text"
                    id="businessManagementNumber"
                    name="businessManagementNumber"
                    value={formData.businessManagementNumber}
                    onChange={handleChange}
                    required
                    style={styles.input}
                  />
                </div>
              </div>
              <div style={styles.inputGroup}>

                <div style={{
                  width: '100%',

                }}>
                  <InputLabel id="demo-simple-select-label">Entidad Bancaria</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ width: '100%' }}
                  >
                    <MenuItem value={"Banco BAC San José S.A"}>
                      <div style={{
                        display: 'flex',
                        gap: 10,
                        alignItems: 'center',
                      }}>
                        <img src="https://www.baccredomatic.com/themes/custom/bac_theme/images/bac_logo.svg" width={30} />
                        Banco BAC San José S.A
                      </div>

                    </MenuItem>
                    <MenuItem value={"Banco BCT S.A"}>

                      <div style={{
                        display: 'flex',
                        gap: 10,
                        alignItems: 'center',
                      }}>
                        <img src="https://plazareal.co.cr/wp-content/uploads/2016/09/Banco-BCT.png" width={30} height={20} />
                        Banco BCT S.A
                      </div>
                    </MenuItem>
                    <MenuItem value={"Banco Cathay de Costa Rica S.A"}>
                      <div style={{
                        display: 'flex',
                        gap: 10,
                        alignItems: 'center',
                      }}>
                        <img src="https://directorioscostarica.com/media/cache/small/custom/domain_1/image_files/791_photo_3766.png" width={30} height={40} />
                        Banco Cathay de Costa Rica S.A
                      </div>
                    </MenuItem>
                    <MenuItem value={"Banco CMB"}>Banco CMB</MenuItem>
                    <MenuItem value={"Banco Davivienda"}>Banco Davivienda</MenuItem>
                    <MenuItem value={"Banco General"}>Banco General</MenuItem>
                    <MenuItem value={"Banco Improsa S.A"}>Banco Improsa S.A</MenuItem>
                    <MenuItem value={"Banco Lafise S.A"}>Banco Lafise S.A</MenuItem>
                    <MenuItem value={"Banco Promérica de Costa Rica S.A"}>Banco Promérica de Costa Rica S.A</MenuItem>
                    <MenuItem value={"Prival Bank (Costa Rica) S.A"}>Prival Bank (Costa Rica) S.A</MenuItem>
                    <MenuItem value={"Scotiabank de Costa Rica S.A"}>Scotiabank de Costa Rica S.A</MenuItem>
                  </Select>
                </div>
              </div>
              <button
                type="submit"
                style={styles.button}
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailForm;