import { useRouteError } from "react-router-dom";
import LOGO from "./Website/images/header.png";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      fontFamily: 'sans-serif',
      backgroundColor: '#FFF',
    }}>
      <img src={LOGO} alt="React logo" style={{ width: 400, height: 300 }} />
      <h1>Oops!</h1>
      <p>Lo siento, ha ocurrido un error inesperado.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}